import React, {useEffect, useState} from 'react';
import * as queryString from 'query-string';
import axios from 'axios';
import {TransitionState} from 'gatsby-plugin-transition-link';

const resp = {
  data: {
    code: 200,
    data: {
      name: 'rafa',
      last_name: 'guz',
      second_lastname: 'bar',
      email: 'rafael@medici-satoshi.com',
      phone: '1231231231',
    },
  },
};

export const Active = ({location, onReturnData}) => {
  // console.log('location => ', location);
  const [stateAux, setStateAux] = useState([]);
  const {token} = queryString.parse(location.search);
  const [stToken, setStToken] = useState(token);
  // console.log('token =>', token);
  const API_KEY = `${process.env.API_KEY}`;
  let URL_ACTIVE = `${process.env.GATSBY_API_URL}:${process.env.PORT}/active?token=${stToken}`;

  if (process.env.NODE_ENV === 'production') {
    URL_ACTIVE = `${process.env.GATSBY_API_URL}/active?token=${stToken}`;
  }

  const [stURL, setStURL] = useState(URL_ACTIVE);
  let dataToken;

  const onSaveStorage = (data) => {
    if (typeof(Storage) !== "undefined") {
      localStorage.setItem('callmatik_response', JSON.stringify(data));
      localStorage.setItem('callmatik_client_token', token.toString());
      // console.log('data =>', data);
    } else {
      // LocalStorage no soportado en este navegador
    }
  };

  const onGetStorage = () => {
    dataToken = localStorage.getItem('callmatik_client_token');
  };

  const onActive = () => {
    axios({
      method: 'get',
      url: stURL,
      headers: {
        'x-api-key': API_KEY,
        'Content-Type': 'application/json',
      },
    }).then(function (response) {
      // console.log('onActive =>',response.data.data[0]);
      // onReturnData(response);
      // console.log('onReturnData => resp =>', resp);
      onReturnData(response.data.data[0]);
      onSaveStorage(response.data.data[0]);
    })
    .catch(function (error) {
      // console.error('error =>', JSON.stringify(error));
      /*alert(`Error=> \n### name: ${error.name}
      \n### Message: ${error.message}
      \n### Stack: ${error.stack}`);*/
    });
  };

  useEffect(() => {
    if (stToken) {
      onActive();
    } else {
      onGetStorage();

      if (dataToken) {
        setStToken(dataToken);
        onActive();
      } else {
        const isBrowser = typeof window && localStorage !== "undefined";
        console.log('isBrowser=>', isBrowser);
        if (isBrowser) {
          // console.log('window.location');
          window.location = '/';
        }
      }
    }
  }, [stToken, stateAux]);

  return (
    <TransitionState>
      {state => {
        // console.log('Active => TransitionState -> state ->', state);
        if ( state.current.state ) {
          setStateAux(state.current.state);
        }
      }}
    </TransitionState>
  );
};
