import React from 'react';
import {ContWizard, ContStep, ContStepNumber, ContStepInfo, LineBottom} from './styles';

export const Wizard = ({step = 0}) => {

  return (
    <ContWizard>
      <ContStep step={step === 1 ? step : 0}>
        <ContStepNumber step={step === 1 ? step : 0}>1</ContStepNumber>
        <ContStepInfo step={step === 1 ? step : 0}>Planes</ContStepInfo>
      </ContStep>
      <ContStep step={step === 2 ? step : 0}>
        <ContStepNumber step={step === 2 ? step : 0}>2</ContStepNumber>
        <ContStepInfo step={step === 2 ? step : 0}>Método de pago</ContStepInfo>
      </ContStep>
      <ContStep step={step === 3 ? step : 0}>
        <ContStepNumber step={step === 3 ? step : 0}>3</ContStepNumber>
        <ContStepInfo step={step === 3 ? step : 0}>Pedido completado</ContStepInfo>
      </ContStep>
    </ContWizard>
  );
};
