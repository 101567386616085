import * as React from "react"
import {useState} from 'react';
import Layout from '../components/Layout'
import SEO from "../components/seo"
import {OurPlans} from '../components/OurPlans';
import {Active} from '../components/Client/Active';
import {Wizard} from '../components/Wizard';

const ClientPage = ({location}) => {
  const [data, setData] = useState([]);

  const onReturnData = (childdata) => {
    console.log('onReturnData......',childdata);
    setData(childdata);
  };

  return (
    <Layout clsOverflow={false} typeRouteMenu={'/#'}>
      <SEO
        title=""
        description=""
        works=''
        bolImage='1'
        ogImage={`home_f.jpg`}
        twitterImage={`home_t.jpg`}
        canonicalUrl="https://www.callmatik.com/client"
      />

      <Active location={location} onReturnData={onReturnData} />

      <Wizard step={1}/>

      <OurPlans inAllsLine={true} spaceTop={false} spaceBottom={true} link={'/payment'} data={data} />

    </Layout>
  )
}

export default ClientPage;
