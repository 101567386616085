import styled, { createGlobalStyle } from 'styled-components'
import {GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';

export const StylesWizard = createGlobalStyle`
  
`
export const ContWizard = styled.div`
  position: relative;
  margin: 25px auto;
  border-bottom: 1px solid ${GlobalColors.colorTextDiscreet};
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: auto;
    margin: 15px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: auto;
    margin: 15px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 650px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 650px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 650px;
  }
  @media(min-width: 1920px){
    width: 650px;
  }
`
export const ContStep = styled.div`
  position: relative;
  top: 1px;
  margin: 0 5px;
  border-bottom: 1px solid ${props => props.step ? GlobalColors.colorPrimary : GlobalColors.colorTextDiscreet};
  
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ContStepNumber = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  margin-bottom: 15px;
  background-color: ${props => props.step ? GlobalColors.colorPrimary : GlobalColors.colorTextDiscreet};
  border-radius: 50%;

  font: 14px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: ${GlobalColors.colorMenuMobilePrimary};
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 20px;
    height: 20px;
    margin-right: 3px;
    font: 12px ${GlobalFonts.fontRegular};
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font: 12px ${GlobalFonts.fontRegular};
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font: 14px ${GlobalFonts.fontRegular};
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font: 14px ${GlobalFonts.fontRegular};
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font: 14px ${GlobalFonts.fontRegular};
  }
  @media(min-width: 1920px){
    font: 14px ${GlobalFonts.fontRegular}; 
  }
`
export const ContStepInfo = styled.div`
  margin-bottom: 15px;
  
  font: 14px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: ${props => props.step ? GlobalColors.colorPrimary : GlobalColors.colorTextDiscreet};

  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    font: 12px ${GlobalFonts.fontRegular};
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font: 12px ${GlobalFonts.fontRegular};
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font: 14px ${GlobalFonts.fontRegular};
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font: 14px ${GlobalFonts.fontRegular};
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font: 14px ${GlobalFonts.fontRegular};
  }
  @media(min-width: 1920px){
    font: 14px ${GlobalFonts.fontRegular}; 
  }
`
